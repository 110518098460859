import * as _ from 'lodash';
export const transNum2ModeArr = (num: number): number[] => {

    let result = [0];
    let loop = 0;
    while (num != 0) {
        loop = Math.floor(Math.log2(num))
        result.push(Math.pow(2, loop));
        num = num - Math.pow(2, loop);
    
    }
    return result;
    // return _.filter(result,function(o){return o!==0});
};
export const transNum2ModeArr2 = (num: number): number[] => {
    return options.filter(o=>(o.value&num) !==0).map(o=>o.value);
    
    // return _.filter(result,function(o){return o!==0});
};

//将数组1，2，4， 转为和的方式返回一个数字，发给后台。 options里有相应的含义，1为执行，2为修改，4为只读，8为执行。
export const transModeArr2Num = (arr: number[]): number => {
    let result = 0;
    arr.forEach(elem => {
        result += elem;
    })
    return result;
}

export const options = [
    // { label: "publish", value: 8, id: 8, name: "publish" },
    { label: "delete", value: 4, id: 4, name: "delete", cnName: '删除' },
    { label: "update", value: 2, id: 2, name: "put", cnName: '更新' },
    { label: "create", value: 1, id: 1, name: "post", cnName: '创建' },
    { label: "read", value: 8, id: 8, name: "get", cnName: '查看' }
];


export const mapOptions =_.groupBy(options,"name");
const bit2option = _.groupBy(options,"value");
/**
* Transfer the data structure of Role from backend to show in frontend
* source:
* {
"rolename": "dfw",
"grantedResource": [
    {
        "resource": "aw",
        "method": 7
    }],
     "views": [
    {
        "view": "awstore",
        "visible": false
    }]
  } 

  Target:
  {
"rolename": "dfw",
"grantedResource": [
    "post:aw",
    "put:aw",
    "delete:aw"
],
"views": [
    "false:awstore"      
],
"resourceFnList":[
{checkList:['post']
title: "templates"}
}],
"viewsFnList":[{"checkList":true
"title": "mbtstore"}]

*/
export function transJSON2Flatten4Role(data: any) {

    let views:any[] = [];
    let grantedResource:any[] = [];
    let checkList:any[] = [];
    let resourceFnList:any[] = [];
    let viewsFnList:any[] = [];
    data.grantedResource.forEach((r: any) => {


        let tempname:string = r.resource; 
        
        let methodsarr = transNum2ModeArr2(r.method);
        
        methodsarr.forEach((m:number)=>{

            let obj = _.filter(options,function(o){return o.value == m});
            grantedResource.push(obj[0].name.concat(':') + tempname);
            checkList.push(obj[0].name)
        })
        resourceFnList.push({'title':tempname,'checkList':checkList}) 
        checkList=[];
    })
    
    data.views.forEach((v: any) => {
        views.push(v.visible+':'+v.view)
        viewsFnList.push({'title':v.view,'checkList':v.visible});
    })
    
    return {
        rolename: data.rolename,
        grantedResource: grantedResource,
        views: views,
        resourceFnList:resourceFnList,
        viewsFnList:viewsFnList
    }

}



/**
* Transfer the data structure of Role from frontend to save to backend
* source:
* 

  
  {
"rolename": "dfw",
"grantedResource": [
    "post:aw",
    "put:aw",
    "delete:aw"
],
"views": [
    false":awstore"      
]
}

Target:
{
"rolename": "dfw",
"grantedResource": [
    {
        "resource": "aw",
        "method": 7
    }],
     "views": [
    {
        "view": "awstore",
        "visible": false
    }]
  } 
*/
export function transFlatten2JSON4Role(data: any) {

    // let localmap ={'post':1, 'put':2,'delete':4,'publish':8}
    let localmap = new Map<string, number>();
    localmap.set('get', 8);
    localmap.set('post', 1);
    localmap.set('put', 2);
    localmap.set('delete', 4);
    // localmap.set('publish', 8);

    let views = [];
    let grantedResource = [];
    let resourcenames: any[] = [];
    let calculatedviews: any[] = [];
    data.grantedResource.forEach((r: any) => {
        let temp = r.split(':');
        resourcenames.push(temp[1]);

    })
    resourcenames = _.uniq(resourcenames);


    data.views.forEach((v: any) => {
        let temp = v.split(':');
        calculatedviews.push(temp[1])
    })

    for (let i = 0; i < resourcenames.length; i++) {
        let obj = Object.assign({ "resource": resourcenames[i], "method": 0 });
        data.grantedResource.forEach((r: any) => {

            let temp = r.split(':');

            if (temp[1] == resourcenames[i]) {
                obj.method += localmap.get(temp[0]);
            }
        })

        grantedResource.push(obj);

    }

    for (let j = 0; j < calculatedviews.length; j++) {
        let obj = Object.assign({ view: calculatedviews[j], visible: false });
        data.views.forEach((v: any) => {
            let temp = v.split(':');

            if (temp[1] == calculatedviews[j]) {
                //   
                obj.visible = /^true$/i.test(temp[0]);

            }
        })

        views.push(obj);
    }

    return {
        rolename: data.rolename,
        grantedResource: grantedResource,
        views: views
    }

}

/**
 * 更改值的类型
 * @author zf
 * @param {value} any
 * @return {val} any
*/
export function typeConversion(value: any, type: string) {
    switch (type) {
        case 'string':
            return String(value);
        case 'number':
            return Number(value);
        case 'array': 
            return Array(value);
        case 'boolean':
            return Boolean(value);
        default:
            return value
    }
}