import { getCookie, removeCookie, setCookie } from "@/utils"
import { defineStore } from "pinia"
import request from "@/utils/request"
import { message } from "ant-design-vue"
import { Stores } from "types/stores"
import {routes} from "@/router";
import {routeNameMap, userProfileAPIs} from "@/enums/cacheEnum";
import { sidebarStore } from '@/stores/sidebar'
import {options, transNum2ModeArr} from "@/utils/dataTransfer";
import cloneDeep from "lodash-es/cloneDeep";

const DEFAULT_AVATAR = 'https://avatars.dicebear.com/v2/female/2fbcf95095f75f17153ca201cd277611.svg'
const DEFAULT_EMAIL = 'example@oppo.com'

export const userStore = defineStore('user', {

  state: (): Stores.user => ({
    name: '',
    token: '',
    email: '',
    avatar_url: '',
    referred_by:null,
    role: { rolename: '', grantedResource: [], views: [] }
  }),
  getters: {
    getUserName: state => state.name,
    getVisibleRoutes: state => (state.role?.views || [])
        .filter((a: any) => a.visible)
        .map((b: any) => routeNameMap[b.view.view]),
    getAPIList: state => {
      const numFnMap: any = options.reduce((pre: any, cur: any) => {
        pre[cur.value] = cur.name
        return pre
      }, {})
      const apis = (state.role?.grantedResource || []).map((a: any) => {
        return {
          name: a.resource.resourceName,
          result: transNum2ModeArr(a.method)
            .map((a: any) => numFnMap[a])
            .filter((b: any) => b)
        }
      })
      let index: number = apis.findIndex((a: any) => a.name === 'user_profile')
      if (index === -1) return apis
      else {
        const _apis = cloneDeep(apis)
        const addArrs: any = userProfileAPIs.map((a: any) => {
          return {
            name: a,
            result: apis[index].result
          }
        })
        _apis.splice(index, 1, ...addArrs)
        return _apis
      }
    }
  },
  actions: {
    async login(username: string, password: string) {
      // const data ={username,password};
      try {
        let res: any = await request.post<Stores.user>('/api/auth/login', {
          email: username, password
        })
        if (res.success) {
          let res1 = await this.getUserInfo()
          return res1
        }
      } catch (e) {
        return Promise.reject(e)
      }

    },
    async logout() {
      return new Promise((resolve) => {
        this.name = ''
        this.email = ''
        this.avatar_url = ''
        this.token = ''
        this.role = { rolename: '', grantedResource: [], views: [] }
        location.href = '/api/auth/logout'
      })
    },
    async getUserInfo(): Promise<string> {
      return new Promise((resolve, reject) => {
        request.get<Stores.user>('/api/user_profile').then((res: any) => {
          if (!res.error) {
            this.name = res.name
            this.email = res.email
            this.avatar_url = res.avatar_url || DEFAULT_AVATAR
            this.referred_by = res.referred_by
            this.role = res.role || []
            this.token = `${encodeURIComponent(res.name)}`
            resolve('Login successful')
            // 路由被权限控制，每一次获取都应该去更新前端路由
            const sideBarStore = sidebarStore()
            sideBarStore.refreshSidebar()
          } else {
            reject(res.error)
          }
        }).catch((res: any) => {
          reject('User not logged, please login')
        })
      })
    },
    async resetPassword(username: string, oldPassword: string, newPassword: string) {
      // const data ={username,password};
      try {
        let res: any = await request.post<Stores.user>('/api/users/change-password', {
          email: username, oldPassword: oldPassword, newPassword: newPassword
        })
        if (res.success) {
          let res1 = await this.getUserInfo()
          return res1
        }
      } catch (e) {
        console.log(e)
        return Promise.reject(e)
      }

    },
  }
})