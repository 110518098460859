import {
  dashboardRoute,
  embedding,
  helpRoute,
  routes
} from "@/router"
import { defineStore } from "pinia"
import { RouteRecordRaw } from "vue-router"
import { userStore } from '@/stores/user'
import {getAuthRoutes} from "@/utils/treeData";
import cloneDeep from "lodash-es/cloneDeep";


export const sidebarStore = defineStore('sidebar', {
  state: (): {
    sidebarList: RouteRecordRaw[]
  } => {
    return {
      sidebarList: []
    }
  },
  actions: {
    refreshSidebar() {
      const _routes = cloneDeep(routes)
      let routeList = _routes.filter((a: RouteRecordRaw) => !a.meta?.hidden)
      const user = userStore()
      const routeAuthList = user.getVisibleRoutes
      routeList = getAuthRoutes(routeList, routeAuthList)
      // this.sidebarList = [dashboardRoute, ...routeList, helpRoute];
      this.sidebarList = [dashboardRoute, ...routeList, helpRoute, embedding]
    }
  },
  getters: {
    getSidebarList: (state) => state.sidebarList
  }
})