/** 用户token */
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

/** 国际化 */
export const LOCALE_KEY = 'LOCALE__';

/** 主题色 */
export const THEME_KEY = 'THEME__';

/** 用户信息 */
export const USER_INFO_KEY = 'USER__INFO__';

// role info key
export const ROLES_KEY = 'ROLES__KEY__';
/** 是否锁屏 */
export const IS_LOCKSCREEN = 'IS_LOCKSCREEN';
/** 标签页 */
export const TABS_ROUTES = 'TABS_ROUTES';

/** 后端路由权限映射 */
export const routeNameMap: any = {
    'awstore': 'Awmodeler',
    'mbtstore': 'Mbtstore',
    'templatemanager': 'templatemanager',
    'meta_templatemanager': 'metaTemplate',
    'static_templatemanager': 'staticTemplate',
    'dynamic_templatemanager': 'pairwiseTemplate',
    'codegen_templatemanager': 'codegenTemplate',
    'settings': 'Settings',
    'webhook': 'webHook',
    'role_manager': 'account',
    'account': 'userauth',
    'resource': 'resource',
}

export const APIMethod2Cn: any = {
    'delete': '删除',
    'put': '更新',
    'post': '创建',
    'get': '查看'
}

export const userProfileAPIs: Array<string> = [
    '/api/roles',
    '/api/resource',
    '/api/view',
    '/api/users'
]