export default {
  okText: 'OK',
  closeText: 'Close',
  cancelText: 'Cancel',
  loadingText: 'Loading...',
  saveText: 'Save',
  delText: 'Delete',
  configText: 'config',
  editText: 'Edit',
  chooseAw: 'chooseAw',
  updateAw: 'updateAw',
  chooseEx: 'choose Expected',
  resetText: 'Reset',
  searchText: 'Search',
  queryText: 'Search',
  updateText: 'Update',
  yesText: 'Yes',
  noText: 'No',
  loadText: 'Load',
  prev: 'Previous',
  next: 'Next',
  preview: 'Preview',
  template: 'Template Settings',
  previewError: 'Code generation failed',
  previewLoad: 'Preview loading',
  saveLoad: 'Save Loading',
  submitText: 'Submit',
  saveError: "Save failed",
  saveSuccess: 'Save successfully',
  modificationSuccess: 'Modification successfully',
  modificationError: 'Modification failed',
  cloneError: 'Clone failed',
  cloneSuccess: 'Clone Success',
  close: 'Close',
  up: 'Up',
  down: 'Down',
  detail: 'Detail',
  view: 'Views visible',
  inputText: 'Please enter',
  chooseText: 'Please choose',

  redo: 'Refresh',
  back: 'Back',

  light: 'Light',
  dark: 'Dark',
  confirm: 'confirm',

  selectTip: 'Please select',

  newTag: 'Add a New Tag',
  newValue: 'Add a New Value',
  clear: 'clear',
  clone: 'clone',
  permissionManagement: 'Permission Management',
  resourceManagement: 'Resource Management',
  roleManagement: 'Role Management',
  goDataPool: 'The current complex condition editing is not available. Do you want to select a template first',
  import:'import',
  export:'export'

};
