export default {
  okText: '确认',
  closeText: '关闭',
  cancelText: '取消',
  loadingText: '加载中...',
  saveText: '保存',
  delText: '删除',
  configText: '设置',
  editText: '编辑',
  chooseAw: '选择Aw',
  updateAw: '修改Aw',
  chooseEx: '选择预期',
  resetText: '重置',
  searchText: '搜索',
  queryText: '查询',
  updateText: '更新',
  yesText: '是',
  noText: '否',
  loadText: '加载',
  prev: '上一步',
  next: '下一步',
  preview: '预览',
  submitText: '保存',
  close: '关闭',
  up: '上升一位',
  down: '下降一位',
  detail: '详情',
  template: '模板设置',
  saveError: "保存失败",
  previewError: '代码生成失败',
  previewLoad: '预览加载中',
  saveLoad: '保存中',
  saveSuccess: '保存成功',
  modificationSuccess: '修改成功',
  modificationError: '修改失败',
  cloneSuccess: '克隆成功',
  cloneError: '克隆失败',
  permissionManagement: '权限管理',
  resourceManagement: '资源管理',
  roleManagement: '角色管理',
  inputText: '请输入',
  chooseText: '请选择',
  clear: '清空',
  redo: '刷新',
  back: '返回',

  light: '亮色主题',
  dark: '黑暗主题',
  confirm: '确定',

  selectTip: '请选择',

  newTag: '添加标签',
  newValue: '添加值',
  clone: '复制',
  goDataPool: '当前复杂条件编辑不可用，请先在模板设置页面添加data pool数据',
  import:'导入',
  export:'导出'
};

