export default {
  excel: {
    exportModalTitle: '导出数据',
    fileType: '文件类型',
    fileName: '文件名',
  },
  form: {
    putAway: '收起',
    unfold: '展开',

    maxTip: '字符数应小于{0}位',

    apiSelectNotFound: '请等待数据加载完成...',
  },
  table: {
    settingDens: '密度',
    settingDensDefault: '默认',
    settingDensMiddle: '中等',
    settingDensSmall: '紧凑',
    settingColumn: '列设置',
    settingColumnShow: '列展示',
    settingIndexColumnShow: '序号列',
    settingFixedLeft: '固定到左侧',
    settingFixedRight: '固定到右侧',
    settingFullScreen: '全屏',
    settingBordered: '边框',
    views: '可见视图',
    index: '序号',
    grantedResources: '资源及控制权限',
    total: '共 {total} 条数据',
    required: "必填",
    returnType: "返回类型",
    unrequired: '非必填',
    name: '名称',
    email: '电邮',
    roles: '角色',
    path: '路径',
    description: '描述',
    tags: '标签',
    url: '网址',
    action: '操作',
    detail: '详情',
    edit: '编辑',
    template: '模板',
    template_en: '英文模板',
    params: '参数',
    paramsName: '参数名',
    type: '类型',
    enum: '枚举',
    values: '值',
    if: '因子',
    then: '则',
    operator: '关系',
    topics: '大纲',
    default: '默认',
    role: '角色',
    clone: '克隆',
    user: '修改人',
    created_by: '创建人',
    updated_at: '修改时间',
    isRequire: '是否必填',

    noCombination: '不参与排列组合',
    pageTip: '当前显示第{head}-{tail}条，共{total}条',
    desktopMeta: '显示测试桌面数据'
  },

  message: {
    modifiedText: '修改成功',
    addText: '添加成功',
    delText: '删除成功',
    emptyTip: '请输入必填项',
    sureDel: '确定要删除吗?',
    sureCancel: '确定要取消吗?',
    saveSuccess: '保存成功',
    updateText: '更新成功',

    dupDescription: '描述不能和现有数据的描述重复',
    emptyDescription: '请输入描述',
    emptyName: '请输入名称',
    depName: '名称不能和现有数据的名称重复',
    emptyUrl: '请输入网址',
    realUrl: '请输入正确网址',
    errTip1: '请先处理正在编辑的数据',

    hefaName: "必须以字母、下划线、或者_开头，后面的部分可以有字母、下划线、美元符$、以及数字组成",
    updateErr: "修改失败",
    nocpuData: '暂无处理器数据',
    nomemoryData: '暂无内存数据',
    noLatencyData: '暂无延迟数据',
    nothroughputData: '暂无吞吐量数据',

    cantUpItem: '已经处于置顶，无法上移',
    cantDownItem: '已经处于置底，无法下移',
    checkEnum: '枚举值不能为空',

    tableEditingWarn: '表格中的数据正在编辑，请先保存它',
    varNameCheck: '名称不合法，只允许含有下划线、数字、字母，且开头不能是数字',
    DuplicateVariableName: '变量名重复',
    settingSuccess: '设置成功！',
    checkSave: '校验不通过，无法保存',
    updateMetaErr: '更新Meta数据失败，请联系管理员'
  },

  route: {
    dashboard: '仪表板',
    awModeler: 'AW管理',
    mtbStore: '测试建模管理',
    templateManager: '模板管理',
    metaTemplate: '用例元数据',
    staticTemplate: '静态数据',
    dynamicTemplate: '动态数据',
    codegenTemplate: '代码生成',
    account: '角色管理',
    webHook: '网络钩子',
    setting: '系统设置',
    resource: '资源管理',
    userauth: '授权管理',
    helpdoc: '帮助文档'
  },

  validate: {
    errArrFormat: '数组格式不正确'
  }
};

