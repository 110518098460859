import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import Layout from '@/layout/index.vue'
import {
  HomeOutlined, ExportOutlined, FireOutlined,
    FieldBinaryOutlined, LineChartOutlined,
  AppstoreAddOutlined, CodeOutlined, LayoutOutlined, ApiOutlined, ApartmentOutlined,
  SettingOutlined, FunctionOutlined, SnippetsOutlined
} from '@ant-design/icons-vue'
import { isEmbedded } from "@/utils/desktop";

/**
 * 如果 MBTStore/MBTModeler 路由有 embedded 参数，实现大屏模式
 * */
function embeddedBigScreen(to: any) {
  if (isEmbedded()) {
    to.matched.forEach((a: any) => {
      if (a.name === 'Mbtstore' || a.name === 'Mbtmodeler') {
        a.components = null
      }
    })
  }
}

export const dashboardRoute: RouteRecordRaw = {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  meta: { breadcrumb: false },
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index.vue'),
      meta: { title: 'component.route.dashboard', icon: HomeOutlined }

    }
  ]
}

export const helpRoute: RouteRecordRaw = {
  path: '/https://mbt.oppo.itealab.net/helpdoc',
  name: 'helpdoc',
  component: undefined,
  meta: { title: 'component.route.helpdoc', icon: SnippetsOutlined, external: true },
  redirect: '/helpdoc/'
}

export const embedding: RouteRecordRaw = {
  path: '/embedding',
  name: 'embedding',
  component: Layout,
  meta: {title: 'component.route.embedding', icon: FunctionOutlined},
  children: [
    {
      path: 'index',
      name: 'embedded',
      component: () => import('@/views/embedding.vue'),
      meta: {title: '嵌入测试', icon: FunctionOutlined, keepAlive: true}
    }
  ]
}

const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true, title: '页面跳转', hiddenTab: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect.vue')
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/err-page/404.vue'),
    meta: { hidden: true, title: '404' },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    meta: { hidden: true },
  }
]

export const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: { hidden: true, title: '登录' }
  },
  {
    path: '/resetpassword',
    name: 'Resetpass',
    component: () => import('@/views/login/resetPassword.vue'),
    meta: { hidden: true, title: '重设密码' }
  },

  {
    path: '/awmodeler',
    name: 'Awmodeler',
    component: Layout,
    redirect: { name: 'awmodeler' },
    meta: { title: 'component.route.awModeler', icon: ApiOutlined },
    children: [
      {
        path: 'index',
        name: 'awmodeler',
        component: () => import('@/views/aw-modeler/index.vue'),
        meta: { title: 'component.route.awModeler', icon: ApiOutlined, keepAlive: true }
      }
    ]
  },
  {
    path: '/mbtstore',
    name: 'Mbtstore',
    component: Layout,
    redirect: { name: 'mbtstore' },
    meta: { title: 'component.route.mtbStore', icon: AppstoreAddOutlined },
    children: [
      {
        path: 'index',
        name: 'mbtstore',
        component: () => import('@/views/mbt-modeler/index.vue'),
        meta: { title: 'component.route.mtbStore', icon: AppstoreAddOutlined, keepAlive: true }
      }
    ],
    beforeEnter(to, from, next) {
      embeddedBigScreen(to)
      next()
    }
  },

  {
    path: '/templatemanager',
    name: 'templatemanager',
    component: Layout,
    redirect: { name: 'staticTemplate' },
    meta: { title: 'component.route.templateManager', icon: ExportOutlined },
    children: [
      {
        path: 'meta',
        name: 'metaTemplate',
        component: () => import('@/views/tempalte-manager/meta-template/index.vue'),
        meta: { title: 'component.route.metaTemplate', icon: FieldBinaryOutlined, keepAlive: true }
      },
      {
        path: 'static',
        name: 'staticTemplate',
        component: () => import('@/views/tempalte-manager/static-template/index.vue'),
        meta: { title: 'component.route.staticTemplate', icon: LineChartOutlined, keepAlive: true }
      }, {
        path: 'dynamic',
        name: 'pairwiseTemplate',
        component: () => import('@/views/tempalte-manager/dynamic-template/index.vue'),
        meta: { title: 'component.route.dynamicTemplate', icon: FireOutlined, keepAlive: true }
      }, {
        path: 'codegen',
        name: 'codegenTemplate',
        component: () => import('@/views/tempalte-manager/codegen-template/index.vue'),
        meta: { title: 'component.route.codegenTemplate', icon: CodeOutlined, keepAlive: true }
      }
    ]
  },
  {
    path: '/metaModeler',
    name: 'MetaModeler',
    component: Layout,
    redirect: { name: 'metaModeler' },
    meta: { hidden: true },
    children: [
      {
        path: ':_id/:name',
        name: 'metaModeler',
        component: () => import('@/views/tempalte-manager/meta-template/components/metaModel.vue'),
        meta: { hidden: true, title: 'MetaModel', icon: LayoutOutlined }
      }
    ],
    beforeEnter(to, form, next) {
      if (to.params._id && to.params.name) {
        to.meta.title = `MetaModel ${to.params.name}`
      }
      next()
    }
  },
  {
    path: '/dynamicModeler',
    name: 'DynamicModeler',
    component: Layout,
    redirect: { name: 'dynamicModeler' },
    meta: { hidden: true },
    children: [
      {
        path: ':_id/:name',
        name: 'dynamicModeler',
        component: () => import('@/views/tempalte-manager/dynamic-template/components/dynamicModel.vue'),
        meta: { hidden: true, title: 'DynamicModeler', icon: LayoutOutlined }
      }
    ],
    beforeEnter(to, form, next) {
      if (to.params._id && to.params.name) {
        to.meta.title = `${to.params.name}`
      }
      next()
    }
  },
  {
    path: '/staticModeler',
    name: 'StaticModeler',
    component: Layout,
    redirect: { name: 'staticmodeler' },
    meta: { hidden: true },
    children: [
      {
        path: ':_id/:name',
        name: 'staticmodeler',
        component: () => import('@/views/tempalte-manager/static-template/components/statictemplateModeler.vue'),
        meta: { hidden: true, title: 'Static Template Modeler', icon: LayoutOutlined }
      }
    ],
    beforeEnter(to, form, next) {
      let pathname = `${to.params.name}`;
      if (to.path == `/staticModeler/${to.params._id}/` + encodeURIComponent(pathname)) {

        to.meta.title = `Static ` + pathname

      }
      next()
    }
  },
  {
    path: '/codegenModeler',
    name: 'CodegenModeler',
    component: Layout,
    redirect: { name: 'codegenModeler' },
    meta: { hidden: true },
    children: [
      {
        path: ':_id/:name',
        name: 'codegenModeler',
        component: () => import('@/views/tempalte-manager/codegen-template/components/codegenModel.vue'),
        meta: { hidden: true, title: 'CodegenModeler', icon: LayoutOutlined }
      }
    ],
    beforeEnter(to, form, next) {
      if (to.params._id && to.params.name) {
        to.meta.title = `${to.params.name}`
      }
      next()
    }
  },
  {
    path: '/awupdate',
    name: 'AWupdate',
    component: Layout,
    redirect: { name: 'awupdate' },
    meta: { hidden: true },
    children: [
      {
        path: ':_id/:name/:awupdate/:mbtid?/:mbtname?',
        name: 'awupdate',
        component: () => import('@/views/aw-modeler/component/updateAw.vue'),
        meta: { title: 'UpdateAw', icon: AppstoreAddOutlined, keepAlive: true }
      }
    ],
    beforeEnter(to, form, next) {
      if (to.params.awupdate == "mbtAW") {
        to.meta.title = `MbtUpdateAw-${to.params.name}`
      } else if (to.params.awupdate == "awmodeler") {
        to.meta.title = `AwUpdate-${to.params.name}`
      }
      next()
    }
  },
  {
    path: '/mbtmodeler',
    name: 'Mbtmodeler',
    component: Layout,
    redirect: { name: 'mbtmodeler' },
    meta: { hidden: true },
    children: [
      {
        path: ':_id/:name',
        name: 'mbtmodeler',
        component: () => import('@/views/mbt-modeler/components/mbt-modeler.vue'),
        meta: { title: 'mbtModel', icon: AppstoreAddOutlined, keepAlive: true }
      }
    ],
    beforeEnter(to, form, next) {
      let pathname = `${to.params.name}`;
      if (to.params._id) {
        to.meta.title = pathname
      }
      embeddedBigScreen(to)
      next()
    }
  },
  {
    path: '/user-manager',
    name: 'Settings',
    component: Layout,
    meta: { title: 'component.route.setting', icon: SettingOutlined },
    children: [
      {
        path: 'webHook',
        name: 'webHook',
        component: () => import('@/views/user-manager/web-hook/index.vue'),
        meta: { title: 'component.route.webHook', icon: FunctionOutlined, keepAlive: true }
      },
      {
        path: 'index',
        name: 'account',
        component: () => import('@/views/user-manager/account/index.vue'),
        meta: { title: 'component.route.account', icon: ApartmentOutlined, keepAlive: true }
      },
      {
        path: 'resource',
        name: 'resource',
        component: () => import('@/views/user-manager/account/resource.vue'),
        meta: { title: 'component.route.resource', icon: ApartmentOutlined, keepAlive: true }
      },
      {
        path: 'userauth',
        name: 'userauth',
        component: () => import('@/views/user-manager/account/userauth.vue'),
        meta: { title: 'component.route.userauth', icon: ApartmentOutlined, keepAlive: true }
      }
    ]
  },
  {
    path: '/https://mbt.oppo.itealab.net/helpdoc',
    name: 'helpdoc',
    component: undefined,
    meta: { title: 'component.route.helpdoc', icon: SnippetsOutlined, external: true },
    redirect: '/helpdoc/'
  }
]
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  // routes: [dashboardRoute, ...routes, ...constantRoutes]
  routes: [dashboardRoute, ...routes, ...constantRoutes, embedding]
})

export default router
