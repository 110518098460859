import { removeCookie, setCookie } from "@/utils"
import { defineStore } from "pinia"
import request from "@/utils/request"
import { message } from "ant-design-vue"
import { Stores } from "types/stores"
import { EnumBody } from "@babel/types"
import { realMBTUrl } from "@/appConfig";
import { i18n } from "@/locales";

const { t } = i18n.global

interface IElementType {
  mbtData: mbtmodel
  rappid: any
  preview: boolean
  chooseDataPool: boolean
  Template: any
  openTem: boolean
  ifCloseLink: boolean;
  highKey: any
  repeatData: any
}

interface codegen {
  codegen_text: string
  codegen_script: string,
  hyperLinks?: any
}
interface dataDafinition {
  data: data
  meta: meta
  resources: any,
}

interface data {
  dataFrom: string
  tableColumns: any
  tableData: any
  title?: string,
  tableId?: string
}

interface meta {
  schema: any
  data: object
  detail: any,
  desktopMeta?: any
}
interface cellsinfo {
  paperscale: any
  cellsinfo: any
  props?: any
  version?: any
}

interface mbtmodel {
  attributes: codegen
  dataDefinition: dataDafinition
  modelDefinition: cellsinfo
  name: string
  _id?: string
  tags?: Array<string>
  description: string,

}
export const MBTStore = defineStore('mbtmodel', {
  state: (): IElementType => {
    return {
      mbtData: {
        attributes: {
          codegen_script: '',
          codegen_text: '',
          hyperLinks: []
        },
        dataDefinition: {
          data: {
            dataFrom: '',
            tableColumns: [],
            tableData: []
          },
          meta: {
            schema: {},
            data: {},
            detail: {},
            desktopMeta: {}
          },
          resources: []
        },
        modelDefinition: {
          cellsinfo: {},
          paperscale: ''
        },
        name: "",
        _id: "",
        tags: [],
        description: "",
      },
      rappid: null,
      preview: false,
      chooseDataPool: false,
      Template: {},
      openTem: false,
      //显示link连接线开关
      ifCloseLink: false,
      // 设置表格背景色
      highKey: null,
      // 重复数据
      repeatData: null
    }

  }
  ,
  getters: {
    getAlldata: state => state.mbtData,
    getcells: state => state.mbtData.modelDefinition.cellsinfo,
    getDafintion: (state) => state.mbtData.dataDefinition,
    changeTemplate(state) {
      let obj = {
        _id: "",
        name: '',
        description: '',
        codegen_script: '',
        codegen_text: '',
        hyperLinks: []
      }
      //  return state.mbtData._id
      if (state?.mbtData?._id && state?.mbtData?._id.length > 0) {
        // return state.mbtData
        obj._id = state.mbtData._id
        obj.name = state.mbtData.name
        obj.description = state.mbtData.description
        if (state.mbtData?.attributes &&
          state.mbtData?.attributes?.codegen_text ||
          state.mbtData?.attributes?.codegen_script &&
          state.mbtData?.attributes?.codegen_script.length > 0) {
          obj.codegen_script = state.mbtData.attributes.codegen_script
          obj.codegen_text = state.mbtData.attributes.codegen_text
          obj.hyperLinks = state.mbtData.attributes.hyperLinks
        }
        return obj;
      }
    },
    showMetaSchema(state) {
      if (state.mbtData.dataDefinition?.meta?.schema) {
        return state.mbtData.dataDefinition.meta.schema
      }
    },
    showMetaData(state) {
      if (state.mbtData.dataDefinition?.meta?.data) {
        return state.mbtData.dataDefinition.meta.data
      }
    },
    getRappid: state => state.rappid,
    getPreview: state => state.preview,
    getChooseDataPool: state => state.chooseDataPool,
    getMeta: state => state.mbtData?.dataDefinition?.meta,
    getDataPool: (state) => {
      if (state.mbtData?.dataDefinition?.data?.tableData) {
        for (const key in state.mbtData?.dataDefinition?.data.tableData) {
          delete state.mbtData?.dataDefinition?.data.tableData[key].action
          delete state.mbtData?.dataDefinition?.data.tableData[key].key
        }
      }
      return state.mbtData?.dataDefinition?.data || []
    },
    getResource: state => state.mbtData?.dataDefinition?.resources,
    getDataPoolDataForm: state => state.mbtData?.dataDefinition?.data?.dataFrom,
    getDataPoolTableData: state => state.mbtData?.dataDefinition?.data?.tableData || [],
    getDataPoolColData: state => state.mbtData?.dataDefinition?.data?.tableColumns || [],
    getMetaDetail: state => state.mbtData?.dataDefinition?.meta?.detail,
    getTemplate(state) {
      let obj = {}
      Object.assign(obj, { attributes: state.mbtData.attributes }, { dataDefinition: state.mbtData.dataDefinition })
      return obj
    },
    getAttrbuites: state => state.mbtData.attributes,
    getChangeTem: state => state.Template,
    getOpenTem: state => state.openTem,
    getIfCloseLink: state => state.ifCloseLink,
    getID: state => state.mbtData.dataDefinition.data.tableId,
    getHighKeys: state => state.highKey,
    getRepeatData: state => state.repeatData,
    getCodegenText: state => state.mbtData.attributes.codegen_text,
    getCodegenScript: state => state.mbtData.attributes.codegen_script
  },



  actions: {
    setMbtData(data: any) {
      this.mbtData = { ...JSON.parse(JSON.stringify(data)) }
    },
    // 存入后台的数据
    // 添加attributes的函数
    saveattr(data: any, linkList: Array<any>) {
      this.mbtData._id = data?._id
      this.mbtData.name = data?.name
      this.mbtData.description = data?.description
      if (this.mbtData.attributes) {
        if (data?.codegen_text || data?.codegen_script) {
          this.mbtData.attributes.codegen_script = data?.codegen_script || ''
          this.mbtData.attributes.codegen_text = data?.codegen_text || ''
          this.mbtData.attributes.hyperLinks = linkList
        } else {
          this.mbtData.attributes.codegen_script = ''
          this.mbtData.attributes.codegen_text = ''
          this.mbtData.attributes.hyperLinks = linkList
        }
      } else {
        let attr: any = { codegen_text: '', codegen_script: '', hyperLinks: [] }
        if (data?.codegen_text || data?.codegen_script) {
          attr.codegen_text = data?.codegen_text || ''
          attr.codegen_script = data?.codegen_script || ''
          attr.hyperLinks = linkList
        } else {
          attr.codegen_text = ''
          attr.codegen_script = ''
          attr.hyperLinks = linkList
        }
        Object.assign(this.mbtData, { attributes: attr })
      }
    },
    saveMeta(data: any) {
      this.mbtData.dataDefinition.meta = data
    },
    saveDesktopMeta(data: any) {
      if (!this.mbtData.dataDefinition.meta) {
        this.mbtData.dataDefinition.meta = {
          schema: {},
          data: {},
          detail: {},
          desktopMeta: data
        }
      } else {
        this.mbtData.dataDefinition.meta.desktopMeta = data
      }
    },
    saveData(data: any, column: any, dataFrom: string, title?: string, tableId?: string) {
      if (this.mbtData.dataDefinition &&
        this.mbtData.dataDefinition.data &&
        this.mbtData.dataDefinition.data.dataFrom
      ) {
        this.mbtData.dataDefinition.data.dataFrom = dataFrom
        this.mbtData.dataDefinition.data.tableData = data
        this.mbtData.dataDefinition.data.tableColumns = column
      } else {
        let obj = { dataFrom: '', tableData: [], tableColumns: [] }
        obj.dataFrom = dataFrom
        obj.tableColumns = column
        obj.tableData = data

        Object.assign(this.mbtData.dataDefinition, { data: obj })
      }
      if (title) this.mbtData.dataDefinition.data.title = title
      if (tableId) this.mbtData.dataDefinition.data.tableId = tableId
    },
    saveResources(data: any) {
      this.mbtData.dataDefinition.resources = data
    },
    saveChangeTem(data: any) {
      this.Template = data
    },
    setGraph(value: any) {
      if (value &&
        this.mbtData.modelDefinition &&
        this.mbtData.modelDefinition.cellsinfo
      ) {
        this.mbtData.modelDefinition.cellsinfo = value
      } else {
        this.mbtData['modelDefinition'] = { cellsinfo: value, paperscale: 1 }
      }
    },
    setRappid(value: any) {
      this.rappid = value
    },
    showPreview(value: boolean) {
      this.preview = value
    },
    setVersion(data: string) {
      if (this.mbtData?.modelDefinition) {
        Object.assign(this.mbtData?.modelDefinition, { schemaVersion: data })
      } else {
        // @ts-ignore
        this.mbtData.modelDefinition = {}
        Object.assign(this.mbtData.modelDefinition, { schemaVersion: data })
      }
      if (this.mbtData.modelDefinition && this.mbtData.modelDefinition?.props) {
        delete this.mbtData.modelDefinition?.props
      }
    },
    saveChooseDataPool(value: boolean) {
      this.chooseDataPool = value
    },
    setResource(arr: any) {
      this.mbtData.dataDefinition.resources = arr
    },

    saveOpenTem(data: boolean) {
      this.openTem = data
    },

    setCloseLink(data: boolean) {
      this.ifCloseLink = data
    },
    setHighKeys(data: any) {
      this.highKey = data
    },
    setRepeatData(data: any) {
      this.repeatData = data
    },
    setCellinfo(data: any) {
      if (this.mbtData?.modelDefinition?.cellsinfo.cells) {
        this.mbtData.modelDefinition.cellsinfo.cells = data
      }
    },
    checkTemplateSettingDataComplete(t: any) {
      const codegen_text = this.mbtData?.attributes?.codegen_text
      const metaData = this.mbtData?.dataDefinition?.meta
      const dataPoolData = this.mbtData?.dataDefinition?.data
      if (codegen_text && metaData && dataPoolData) return true
      else {
        if (!codegen_text) message.warning(t('MBTStore.lackTextScript'))
        if (!metaData) message.warning(t('MBTStore.lackMetaData'))
        if (!dataPoolData) message.warning(t('MBTStore.lackDataPoolData'))
        return false
      }
    },
    // 存入codegen_text数据
    saveCodegenText(data: string) {
      let attr = {
        codegen_text: this.mbtData?.attributes?.codegen_text ? this.mbtData?.attributes?.codegen_text : '',
        codegen_script: this.mbtData?.attributes?.codegen_script ? this.mbtData?.attributes?.codegen_script : '',
        hyperLinks: this.mbtData?.attributes?.hyperLinks ? this.mbtData?.attributes?.hyperLinks : [],
      }
      if (this.mbtData.attributes) {
        if (this.mbtData.attributes?.codegen_text) {
          this.mbtData.attributes.codegen_text = data ? data : ''
        } else {
          attr.codegen_text = data
        }
      } else {
        attr.codegen_text = data
      }
      Object.assign(this.mbtData, { attributes: attr })

    },
    // 存入codegen_text数据
    saveCodegenScript(data: string) {
      let attr = {
        codegen_text: this.mbtData?.attributes?.codegen_text ? this.mbtData?.attributes?.codegen_text : '',
        codegen_script: this.mbtData?.attributes?.codegen_script ? this.mbtData?.attributes?.codegen_script : '',
        hyperLinks: this.mbtData?.attributes?.hyperLinks ? this.mbtData?.attributes?.hyperLinks : [],
      }
      if (this.mbtData.attributes) {
        if (this.mbtData.attributes?.codegen_script) {
          this.mbtData.attributes.codegen_script = data ? data : ''
        } else {
          attr.codegen_script = data
        }
      } else {
        attr.codegen_script = data
      }
      Object.assign(this.mbtData, { attributes: attr })
      // this.mbtData.attributes.codegen_script = data?data:''
    }
  }
})

export default MBTStore



//  选择AW、 选择预期 修改Aw
// 点击上一个  aw之后，新加的一个aw无法编辑


// cd 文件夹
//crontab-1  修改每天修改信息
// less pull 进入文件中查看
// vim 为编辑文件内容
//ls -ltr查看当前文件夹中的文件
//zai  code下直接执行  ./redeploy.sh

// modelSchema