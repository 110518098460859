import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import 'ant-design-vue/es/message/style/index.css' // antdv message样式
import 'ant-design-vue/es/modal/style/index.css' // antdv message样式
import 'ant-design-vue/dist/antd.less';
import './composables/css/templateSet.css'
import 'ant-design-vue/dist/antd.variable.min.css';
import 'dayjs/locale/zh-cn';
import '@/styles/index.postcss' // 全局样式
import '@/styles/index.less'
import 'virtual:svg-icons-register'
import './permission'
import { EnvType } from 'types/app'
import { i18n } from '@/locales'
import { Form, Input, InputNumber, Button, Drawer, Switch, Slider, Select, SelectOption, Space, Radio, Checkbox } from 'ant-design-vue';

createApp(App).use(Form)
    .use(Input)
    .use(InputNumber)
    .use(Button)
    .use(Switch)
    .use(SelectOption)
    .use(Select)
    .use(Space)
    .use(Slider)
    .use(Drawer)
    .use(Radio)
    .use(Checkbox)
    .use(i18n)
    .use(createPinia())
    .use(router).mount('#app')
