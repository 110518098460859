<template>
  <div class="schema-form-item">
<!--    :open="props.readonly-->
    <a-select
      :options="options"
      :allowClear="!readonly"
      :open="isShowOptions"
      mode="tags"
      v-model:value="selectValue"
      @change="selectChange"
      @focus="handleFocus"
      @blur="handleBlur"
    ></a-select>
    <div class="type-tip">Array</div>
  </div>
</template>

<script setup lang="ts">
import {
  watch,
  ref,
  computed
} from "vue";

const emit = defineEmits(['update:modelValue'])
interface Props {
  modelValue: string,
  options: any,
  readonly: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  options: [],
  readonly: false
})

const selectValue = ref([])
const showOptions = ref<boolean>(true)

const isShowOptions = computed(() => {
  return !props.readonly && showOptions.value
})

function selectChange () {
  emit('update:modelValue', selectValue.value)
}

function handleBlur() {
  showOptions.value = false
}

function handleFocus() {
  showOptions.value = true
}

watch(
    () => props.modelValue,
    (val: any) => {
      if (props.readonly) {
        selectValue.value = props.options.map((a: any) => a.value)
      } else selectValue.value = val || []
    },
    { immediate: true }
)

</script>

<style scoped lang="less">
.schema-form-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  .type-tip {
    background-color: white;
    height: 30px;
    line-height: 30px;
  }
}
</style>