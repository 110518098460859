export default {

    step1: '第一步',
    step2: '第二步',
    step3: '第三步',
    step4: '第四步',
    defineTem: '模板设置',
    defineCell: '元素含义',
    defineData: '数据配置',
    defineLast: '代码生成',


    stepContent1: '点击选择模板后，在Attributes中设置代码生成的模板，在meta中选择用例元数据的模板，在Data Pool中选择数据的模板，在Resources中设置资源',
    stepContent2: '各元素含义,鼠标移入后提示，group为满足循环，retry等场景，ifGroup为满足配置条件，section配置前置或后置条件',
    stepContent3: '拉取元素至画布，在右侧编辑数据',
    stepContent4: '建模完成后，点击保存，可以预览生成代码，若当前建模中的AW不存在，则需重新加载',
};