export default {
  email: ' 账号',
  visible: '是否可见',
  roleManagement: '角色管理',
  permissionManagement: '权限管理',
  rolename: '角色',
  inputSearch: '输入查找的角色 ',
  view: '视图',
  views: '视图',
  resourceName: '资源',
  method: '权限',
  grantedResource: '资源',
  viewdetail: '视图',
  visibledetail: '是否可见',
  viewstore: '视图',
  userName: '用户名',
  cnName: '姓名',
  jobNumber: '工号',
  authrole: '角色',
  account: '角色管理',
  setting: '系统设置',
  userauth: '授权管理',
  detailRole: '角色管理',
  updateRole: '角色更新',
  saveRole: '角色保存',
  action:'操作',  
  updateUserAuth: '更新权限',
  saveUserAuth: '保存权限',
  detailUserAuth: '权限设定'

};
