export default {
  excel: {
    exportModalTitle: 'Export data',
    fileType: 'File type',
    fileName: 'File name',
  },
  form: {
    putAway: 'Put away',
    unfold: 'Unfold',
    maxTip: 'The number of characters should be less than {0}',
    apiSelectNotFound: 'Wait for data loading to complete...',
  },
  table: {
    settingDens: 'Density',
    settingDensDefault: 'Default',
    settingDensMiddle: 'Middle',
    settingDensSmall: 'Compact',
    settingColumn: 'Column user-manager',
    settingColumnShow: 'Column display',
    settingIndexColumnShow: 'Index Column',
    settingFixedLeft: 'Fixed Left',
    settingFixedRight: 'Fixed Right',
    settingFullScreen: 'Full Screen',
    settingBordered: 'Bordered',
    index: 'Index',
    total: 'total of {total}',
    views: 'Views visible',
    required: 'Required',
    unrequired: 'Not required',
    name: 'Name',
    path: 'Path',
    returnType: "returnType",
    description: 'Description',
    tags: 'Tags',
    url: 'Url',
    action: 'Action',
    detail: 'Detail',
    edit: 'Edit',
    template: 'Template',
    template_en: 'Template_en',
    params: 'Params',
    paramsName: 'ParamsName',
    type: 'Type',
    enum: 'Enum',
    values: 'Values',
    if: 'IF',
    then: 'Then',
    operator: 'Operator',
    topics: 'Topics',
    default: 'Default',
    grantedResources: 'Resources and operations',
    clone: 'clone',
    user: 'user',
    updated_at: 'updated_at',
    created_by: 'created_by',
    role: 'Role',
    isRequire: 'Is required',
    noCombination: 'Not participating in permutation and combination',
    pageTip: 'The {head} to {tail} currently displayed, {total} in total',
    desktopMeta: 'Show test desktop data'
  },

  message: {
    modifiedText: 'Modified successfully',
    addText: 'Added successfully',
    delText: 'Delete Successfully',
    emptyTip: 'Please enter required items',
    sureDel: 'Sure to delete?',
    sureCancel: 'Sure to cancel?',
    saveSuccess: 'Saved successfully',
    updateText: 'Update successfully',

    dupDescription: 'Duplicate description',
    emptyDescription: 'Please input your description',
    emptyName: 'Please input your name',
    depName: 'Duplicate name',
    emptyUrl: 'Please input url',
    realUrl: 'Please input correct url',
    errTip1: 'Please process the data being edited first',
    hefaName: "The name format includes English,Number,_,$",

    nocpuData: 'No CPU Data',
    nomemoryData: 'No Memory Data',
    noLatencyData: 'No Latency Data',
    nothroughputData: 'No Throughput Data',
    checkEnum: 'Enumeration value cannot be empty',

    cantUpItem: 'Already at the top, unable to move up',
    cantDownItem: 'already at the bottom, unable to move down',

    tableEditingWarn: 'The data in the table is being edited, please save it first',
    varNameCheck: 'The name is illegal, only underscores, numbers, letters are allowed, and the beginning cannot be a number.',
    DuplicateVariableName: 'Duplicate variable name',
    settingSuccess: 'Setting settingSuccess!',
    checkSave: 'The check failed and could not be saved',
    updateMetaErr: 'Failed to update Meta data, please contact administrator'
  },

  route: {
    dashboard: 'Dashboard',
    awModeler: 'AW Store',
    mtbStore: 'MBT Store',
    templateManager: 'TemplateManager',
    metaTemplate: 'Meta',
    staticTemplate: 'Static',
    dynamicTemplate: 'Dynamic',
    codegenTemplate: 'Codegen',
    account: 'Role',
    webHook: 'Web Hook',
    setting: 'Settings',
    resource: 'Resource',
    userauth: 'Users',
    helpdoc: 'helpdoc'
  },

  validate: {
    errArrFormat: 'Array format is incorrect'
  }
};
// resourceseditableData
