<script setup lang="ts">
  import { onBeforeMount, provide, reactive, ref } from 'vue'
  import HeadBar from './HeadBar.vue'
  import SideBar from './SideBar.vue'
  import TabsBar from './TabsBar.vue'
  import isMobile from '@/composables/isMobile'
  import Logo from '@/assets/logo.svg'
  import { transitions, fixedHeader } from '@/appConfig'
  import type { Layout } from 'types/layout'
  const _isMobile = isMobile(setSidebarCollapsed)
  const sidebarRelated = reactive<Layout.SidebarRelated>({
    collapsed: true,
    width: '15rem',
    collapsedWidth: '3rem'
  })
  const loading = reactive<Layout.Loading>({
    logout: false
  })
  const keepAlivePages = ref<Layout.keepAlivePages>(new Set())
  onBeforeMount(() => {
    setSidebarCollapsed()
  })
  function setSidebarCollapsed() {
    sidebarRelated.collapsed = _isMobile.value
  }
  // 为子组件提供布局的相关状态信息
  provide('sidebarRelated', sidebarRelated)
  provide('keepAlivePages', keepAlivePages.value)
  provide('loading', loading)
  </script>
  
  <template>
    <ALayout>
      <ALayoutSider v-if="!_isMobile" v-model:collapsed="sidebarRelated.collapsed" collapsible :trigger="null"
        :width="sidebarRelated.width" :collapsedWidth="sidebarRelated.collapsedWidth" breakpoint="md">
        <div style="display: flex; flex-direction: column; width: 100%; height: 100%;">
          <RouterLink to="/">
            <!-- <AImage width="100%" :height="sidebarRelated.collapsed ? '3rem' : '4rem'"
              style="padding: .3rem 0; object-fit: contain;" :src="Logo" :preview="false" /> -->
              <img style="width:4rem;height: 4rem;" :src="Logo"/> MBT
          </RouterLink>
          <SideBar :style="{ paddingRight: sidebarRelated.collapsed ? '0' : '0' }"></SideBar>
        </div>
      </ALayoutSider>
      <ALayout>
        <ALayoutHeader v-if="fixedHeader">
          <HeadBar></HeadBar>
          <TabsBar :withIcons="true"></TabsBar>
        </ALayoutHeader>
        <ALayoutContent id="content-window">
          <div v-if="!fixedHeader" style="padding: 0 1rem;">
            <HeadBar></HeadBar>
            <TabsBar :withIcons="true"></TabsBar>
          </div>
        <KeepAlive :include="Array.from(keepAlivePages)" :max="10">
          <RouterView v-slot="{ Component, route }" class="content-view" :key="$route.path">
            <Transition :name="transitions.fadeScale" mode="out-in" appear>
              <!-- 
                vite的hmr和keepalive组件冲突会导致路由失效，
                https://github.com/vuejs/core/pull/5165
                开发过程注释掉keepalive
              -->

                <component :is="Component" :key="route.meta.title" />
            </Transition>
          </RouterView>
            </KeepAlive>

        </ALayoutContent>
      </ALayout>
    </ALayout>
    <Teleport to="body">
      <Transition name="slide-right" mode="out-in" appear>
        <Shadow v-if="_isMobile && !sidebarRelated.collapsed" @shadowClick="sidebarRelated.collapsed = true">
          <div class="block sidebar-mobile">
            <RouterLink to="/">
              <AImage :width="sidebarRelated.width" height="6rem" style="padding: .3rem 0; object-fit: contain;"
                :preview="false" :src="Logo" />
            </RouterLink>
            <SideBar></SideBar>
          </div>
        </Shadow>
      </Transition>
    </Teleport>
  </template>
  
  <style scoped>
  .sidebar-mobile {
    width: v-bind('sidebarRelated.width');
    height: 96vh;
    position: absolute;
    top: 2vh;
    left: 2vw;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .content-view {
    padding: 0.2rem;
  }

  #content-window {
    padding: 0.2rem;
  }
  </style>