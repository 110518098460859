export default {
    saveTitle: 'Save MBT',
    updateTitle: 'Update MBT',
    searchText: "input {'@'} to search tags, input name to search MBT",
    tip1: 'Duplicate name or description',
    tip2: 'name and description is required',
    tip3: 'Delete on Successed',
    tip5: 'Please input desc!',
    tip6: 'Are you sure delete this task?',
    newMbt: 'New modeling',
    newTem: 'Create a template',
    templateMbt: 'Modeling from templates',
    noStart: 'Not started',
    noTemplate: 'Currently, there are no templates available for selection',

    // MBTModeler
    addAssert: 'Add assert',
    reloadTip: 'MBT model reloaded',
    reloadError: 'Reload failed',
    noLoad: 'Loading not completed, please wait',
    noreload: 'The current model does not have AW and does not need to be overloaded',
    start: 'Start',
    end: 'End',
    concurrency: 'Concurrency',
    branch: 'branch',
    previewModel: 'Preview Modal',
    noPreviewData: 'No preview data yet',
    saveNewMbt: 'Save as NewModel',
    saveDirect: 'SaceDirectly',
    saveuser: 'The current model has been modified by',
    isSaveNew: ' Do you want to save it as a new model?',
    // MBTModeler detail
    primary: 'Primary',
    expected: 'Expected',
    configAW: 'Configuration for the AW',
    leaveRouter: 'The current edit has not been saved. Do you want to exit after saving?',
    exitDire: 'SimplyExit',
    saveNoLea: 'SaveWithoutExiting',
    saveLeave: 'Save&Exit',
    notSave: 'The current model is not saved',
    loadsuccess: 'Successfully loaded',
    editData: 'EditData',
    editStyle: 'StyleModification',
    instructions: 'instructions',
    noCase: 'The current model has not generated a use case, please check',
    // linkTitle: 'Configuration for Link'
    configLink: 'configLink',
    varErrTip: 'The entered value does not allow spaces!',

    // mbt-modeler-aw-schema
    updateAw: 'Edit AW',
    deleteAW: 'Unselect AW',
    reloadAW: 'reload AW',
    clearAssert: 'Clear assert',
    reloadSuccess: 'Loading succeeded',
    reloadErr: 'Current AW does not exist',

    // mbt-preview-modal.vue
    selectTip: 'Please select the left node to display the data',
    previewInput: 'The current page has custom AW, whether to output as text',

    // mbt-modeler-template-web-hook
    addHyperLinke: 'Add reference',

    // mbt-modeler-template-setting
    lackTextScript: 'Please select text script in the Attribute tab of template setting',
    lackMetaData: 'Please select meta in the Meta tab of template setting',
    lackDataPoolData: 'Please select data in the data pool tab of template setting',
    noData: 'The current template does not exist, please reconfigure it',
    notChange: 'Unable to perceive whether dataPool data has changed, please choose independently',
}