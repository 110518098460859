export default {
    delTemp: 'Template deleted successfully',
    newTemp: 'New template',
    nameRequire: 'Please input the name of model',
    checkEnum: 'The value entered does not match the type',
    depName: 'This parameter already exists',
    isemptyTemplate:'The current template data is empty, please set it first',

    // meta template
    searchText: "input {'@'} to search tags, input name to search meta template",
    metaSearchText: "input {'@'} to search tags, input name to search meta template",
    metaName: "Meta Name",
    metaDescription: "Meta Description",
    nameinput: "place input your name",
    namehefa: "Must be alphabetic, underlined, or _ Start with letters, underscores, dollar signs, $, and numbers, and no spaces",
    duplicate: "Duplicate name",
    description: "place input your description",

    // static template
    staticSearchText: "input {'@'} to search tags, input name to search static template",
    // static modeler
    addColumn: 'Add column',
    addRow: 'Add row',
    StaticName: "Static Name",

    // dynamic template
    dynamicName: "dynamic Name",
    dynamicSearchText: "input {'@'} to search tags, input name to search dynamic template",
    createModelSuccess: 'Created a model successfully',
    updateModelSuccess: 'Updated a model successfully',
    saveModelSuccess: 'Model is saved successfully',
    readModelErr: 'Cannot read the model information',
    createDynamicTemp: 'Create a New Dynamic Template',
    updateDynamicTemp: 'Update a Dynamic Template',
    template: 'modal',
    delDynamicTemp: 'Are you sure to delete this Dynamic Template?',
    // dynamicModel
    dynamicDelText: 'Please complete the following editing first',
    optionLabel: 'Option (required)',
    strategyLabel: 'Strategy',
    factorsLabel: 'Factors (required)',
    newFactor: 'Add a New Factor',
    constraintLabel: 'Constraint (optional)',
    addition:'Advanced Restraints (optional)',
    newConstraint: 'Add a New Constraint',
    saveModel: 'Save Model',
    saveModelTip: 'It is requires at least TWO factors in a model',
    saveTip: 'Please save all the editing fields first',
    previewModel: 'Model preview',
    data: 'data',
    delConstraint: 'Are you sure to delete this strategy?',
    delFactor: 'Are you sure to delete this factor?',
    constraintUnd:'The current constraint format is incorrect. Please check and try again',
    constraintErr:'The factor of the current constraint does not match its corresponding enumeration',
    constraintDesc:'Constraint Description',

    // codegen template
    codegenName: "Codegen Name",
    codegenDes: "Codegen Description",
    codegenSearchText: "input {'@'} to search tags, input name to search CodeGen Templates",
    newCodegenTemp: 'Create a New CodeGen Template',
    updateCodegenTemp: 'Update a CodeGen Template',
    delCodegenTemp: 'Are you sure to delete this CodeGen Template?',
    // code modeler
    timeStamp: 'Time Stamp',
    templateEngine: 'Template Engine',
    outLang: 'Output Language',
    outputType: 'Output Tupe',
    theme: 'theme',
    editTemp: 'Edit Template',
    previewTemp: 'Preview Result (Read-only)',
    saveApreview: 'Save & Preview',
    editData: 'Edit Data',
    queryFail: 'Query failed!',
    loadSuccess: 'Load successful',
    emptyTemplateEngine: 'Template engine cannot be null!',
    templateErr: 'Something wrong in the Template',
    previewSuccess: 'Preview successful!',
    saveErr: 'Save failed!',
    emptyOutput: 'Please select a Output Language',
    emptyTemplate: 'Please select a Template Engine',
    noTemplate: 'Model cannot be found'
}