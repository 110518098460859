export default {
    inputSearch: '请输入要搜索的内容',
    inputSearch1: "输入{'@'}搜索标签，输入名称搜索",
    addParams: '添加参数',
    addSNode: '添加同级节点',
    addCNode: '添加子节点',
    modifyNode: '修改节点',
    moveSelected: '移动选择数据',
    delNode: '删除节点',
    delTip: '确定删除该元用例?',
    tip1: '此节点已包含的节点数为',
    tip2: '请先完成修改',
    emptyTemp: '模板或英文模板是必填的',
    pageTotal: '共{total}条',
    selectTip: '请选择',
    selectAwTip: '请选择要添加的AW',
    paramsSave:'参数必须设置类型'
}