import { message } from 'ant-design-vue'
import axios from 'axios'
import { getCookie, getUrlParams, setCookie } from '.'
import router from '@/router'
import { getDesktopToken, isEmbedded, isEmbeddedReadOnly } from "@/utils/desktop";
import {userStore} from "@/stores/user";
import {APIMethod2Cn} from "@/enums/cacheEnum";
import { requestData } from './adapter';
// import io from 'socket.io-client'

const request = axios.create({
  timeout: 60000,
  maxContentLength: 1024 * 1024 * 20,
  maxBodyLength: 1024 * 1024 * 20
})

			
			// 建立websocket连接
			// const socket = io('http://localhost:8081', {
      //   transports:['websocket']
      // })



const token = getCookie('token')

request.interceptors.request.use(
  config => {
    if (config.headers && token) {
      config.headers['Authorization'] = token
    }
    if (config.headers && getDesktopToken() && isEmbedded()) {
      config.headers['access_token'] = getDesktopToken()
    }
    // 如果在内嵌模式下且用户权限为 viewer，不允许在模型中点击保存操作
    const reg = new RegExp(/api\/test-models\/[A-Za-z0-9]+$/, 'g')
    if (isEmbedded()) {
        if (isEmbeddedReadOnly()
            && reg.test(config.url || '')
            && config.method !== 'get') {
            message.warning('只读权限下不能保存！')
            return Promise.reject()
        }
    } else {
        const user = userStore()
        const _url = config.url || ''
        const flag = user.getAPIList
            .some((a: any) => _url.indexOf(a.name) > -1 && !a.result.includes(config.method))
        if (flag) {
            message.warning(`当前权限下不能${APIMethod2Cn[config.method || '']}操作！`)
            return Promise.reject()
        }
    }
    return config
  },
  error => {
    console.error(error)
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    const { code, msg } = response.data
    // if(response.config.method !== 'get'){
    //     // console.log(requestData(response))
    //     socket.emit('MBT',{mbtFit:requestData(response)})
    // }
    if (code == 400 || response.status == 400) {
      // message.error(`错误码${code}：${msg || '未知错误'}`, 5)
      message.error("请求失败，请重试")
      return Promise.reject(new Error("请求失败，请重试"))
    } else if (code == 403 || response?.status == 403) {
      message.error("当前角色无权限，请重试")
    } else {
      return response.data
    }
  },
  error => {
    const notInLoginPage = location.href.indexOf('#/login') === -1
    if (error?.response?.status == 401 && error?.request?.custom?.options?.url !== '/api/auth/login') {
      sessionStorage.setItem('login_redirect_url', notInLoginPage ? window.location.href : '')
      router.push({ name: 'Login' })
    } else if (error?.response?.status == 403) {
      message.error("暂无权限，请联系管理员")
    }
    return Promise.reject(error)
  }
)

export default request