export default {
    delTemp: '删除模板成功',
    newTemp: '新建模板',
    nameRequire: '请输入模板名称',
    descriptionRequire: '请输入模板描述',
    template: '模板',
    checkEnum: '输入的值与类型不符合',
    depName: '该参数已存在',
    isemptyTemplate:'当前模板数据为空，请先设置',

    // meta template
    metaDelTemp: '测试模板已成功删除',
    metaSearchText: "输入{'@'}搜索标签，输入名称搜索原组件名称",
    metaName: "模板名称",
    metaDescription: "模板描述",
    nameinput: "请输入名称",
    namehefa: "必须以字母、下划线、或者_开头，后面的部分可以有字母、下划线、美元符$、以及数字组成，不能有空格",
    duplicate: "名称已存在",
    description: "请输入描述信息",

    // static template
    staticSearchText: "输入{'@'}搜索标签，输入名称搜索静态模板名称",
    // static modeler
    addColumn: '添加列',
    addRow: '添加行',
    StaticName: "静态模板名称",


    // dynamic template
    dynamicSearchText: "输入{'@'}搜索标签，输入名称搜索动态模板名称",
    createModelSuccess: '创建模板成功',
    updateModelSuccess: '更新模板成功',
    saveModelSuccess: '保存模板成功',
    readModelErr: '无法获取模板信息',
    createDynamicTemp: '创建动态模板',
    updateDynamicTemp: '更新动态模板',
    delDynamicTemp: '是否确定要删除此动态模板?',
    // dynamicModel
    dynamicDelText: '请先完成下方编辑',
    optionLabel: '选项（必填）',
    strategyLabel: '策略',
    factorsLabel: '因子（必填）',
    newFactor: '新建因子',
    newValue: '添加值',
    constraintLabel: '约束（可选）',
    addition:'高级约束（可选）',
    newConstraint: '新增约束',
    saveModel: '保存模板',
    saveModelTip: '模板中至少需要两个因子',
    saveTip: '请先保存所有编辑字段',
    previewModel: '预览模板',
    data: '数据',
    delConstraint: '是否确定要删除此约束?',
    delFactor: '是否确定要删除此因子?',
    constraintUnd:'当前约束格式不正确，请检查后重试',
    constraintErr:'当前约束的因子与其对应的枚举不相符',
    constraintDesc:'约束描述',

    // codegen template
    codegenName: "编码名称",
    codegenDes: "编码描述",
    codegenSearchText: "输入{'@'}搜索标签，输入名称搜索编码模板名称",
    newCodegenTemp: '新建编码模板',
    updateCodegenTemp: '更新编码模板',
    delCodegenTemp: '是否确认删除此编码模板?',
    // codegen modeler
    timeStamp: '时间戳',
    templateEngine: '模板引擎',
    outLang: '输出格式',
    outputType: '输出类型',
    theme: '主题',
    editTemp: '编辑模板',
    previewTemp: '预览模板（只读）',
    saveApreview: '保存并预览',
    editData: '编辑数据',
    queryFail: '获取数据失败!',
    loadSuccess: '加载成功',
    emptyTemplateEngine: '模板引擎不能为空！',
    templateErr: '模板发生问题',
    previewSuccess: '预览成功',
    saveErr: '保存失败',
    emptyOutput: '请选择一种输出格式',
    emptyTemplate: '请选择一种模板引擎',
    noTemplate: '找不到模板'
}