import _ from "lodash";
import { uuid } from "@/utils/Uuid";

// 对象转数组
export function objToArr(obj: any) {
    const arr = []
    if (_.isObject(obj)) {
        for (let i in obj) {
            let oo: any = {
                title: i,
                key: uuid(),
                children: objToArr(obj[i as keyof typeof objToArr])
            };
            if (i === "") {
                i = "/"
                oo = {
                    title: i,
                    key: uuid(),
                    children: objToArr(obj["" as keyof typeof objToArr])
                }
            }
            arr.push(oo)
        }
    }
    return arr
}

/**
 * 文件目录结构转化为树结构
 * */
export function arr2Tree(arr: Array<string>) {
    let treeDTO: any = []
    arr.forEach((item: string) => {
        const nodeArray = item.split('/')
        let children = treeDTO
        // 循环构建子节点
        for (const i of nodeArray) {
            const node = {
                title: i || '/'
            }
            if (children.length === 0) {
                children.push(node)
            }
            let isExist = false
            for (const j in children) {
                if (children[j].title === node.title) {
                    if (!children[j].children) {
                        children[j].children = []
                    }
                    children = children[j].children
                    isExist = true
                    break
                }
            }
            if (!isExist) {
                children.push(node)
                if (!children[children.length - 1].children) {
                    children[children.length - 1].children = []
                }
                children = children[children.length - 1].children
            }
        }
    })
    return treeDTO
}

/**
 * 在树形结构寻找某个指定树形的节点
 * 并返回路径
 * 通过 children 字段向下寻找
 * */
export function findTreeNode(attr: string, val: any, tarVal: string, list: Array<any>) {
    let nodeArr: any = []
    function getNodeRoute(list: Array<any>) {
        for (let index = 0; index < list.length; index++) {
            if (list[index].children) {
                let endRecursiveLoop = getNodeRoute(list[index].children)
                if (endRecursiveLoop) {
                    nodeArr.push(list[index][tarVal])
                    return true
                }
            }
            if (list[index][attr] === val) {
                nodeArr.push(list[index][tarVal])
                return true
            }
        }
    }
    getNodeRoute(list)
    return nodeArr
}

/**
 * 扁平化树形结构
 * 通过 children 字段向下寻找
 * */
export function platTreeData(list: Array<any>) {
    return list.reduce((res, node) => {
        res.push(node)
        if (node.children) res = res.concat(platTreeData(node.children))
        return res
    }, [])
}

/**
 * 根据 antd tree 组件点击事件的 info 返回路径字符串
 * */
export function aTreeInfo2Path(info: any) {
    const pNodes = info.node?.parent?.nodes || []
    return String(
        pNodes.map((a: any) => a.title).join('/') + '/' + info.node.title
    ).slice(1)
}

/**
 * 处理内嵌模式下 treePath 倒数两个路径有带上 id，需要处理
 * 格式：title/title/epicId_title/featureId_title
 * */
export function handleTreePath(path: string) {
    let arr = path.split('/').filter((a: any) => a)
    let epicId = ''
    let featureId = ''
    if (arr.length !== 4) return {
        path: `/${arr.join('/')}`
    }
    // 后续可能会用到
    let arr1 = arr.slice(-2)
    let arr2 = arr.slice(0, -2)
    const epicStr = arr1[0]
    const featureStr = arr1[1]
    let epicTitle = epicStr
    let featureTitle = featureStr
    if (epicStr.indexOf('_') !== -1) {
        epicId = arr1[0].split('_')[0]
        epicTitle = epicStr.split('_')[1]
    }
    if (featureStr.indexOf('_') !== -1) {
        featureId = arr1[1].split('_')[0]
        featureTitle = featureStr.split('_')[1]
    }
    const treePath: string = arr2.concat([epicTitle, featureTitle]).join('/')
    return {
        path: `/__INTERNAL__/${treePath}`,
        epicId,
        featureId
    }
}

/**
 * 通过某个属性值，返回该节点的父节点
 * */
export function findParentNode(key: string, treeData: any) {
    if (treeData.key === key) {
        return null
    }
    for (let i = 0; i < treeData.children.length; i++) {
        const child = treeData.children[i]
        if (child.key === key) {
            return treeData
        }
        const parent: any = findParentNode(key, child)
        if (parent) {
            return parent
        }
    }
    return null
}

/**
 * 树形结构数据筛选
 * routes: 带筛选数据
 * routeAuthList: 能显示在页面得数据
 * */
export function getAuthRoutes(routes: any, routeAuthList: Array<string>) {
    let temp: any[] = []
    routes.forEach((a: any) => {
        if (routeAuthList.includes(a.name)) {
            if (a.children?.length) a.children = getAuthRoutes(a.children, routeAuthList)
            temp.push(a)
        }
    })
    return temp
}