import { defineStore } from 'pinia'

interface toolState {
    joinObj: any,
    visible: boolean,
    errList: any,
    check: boolean
    errmsg: any
    index: number
    recommended_data: null
    linkData: any
}

export const errTipTool = defineStore({
    id: 'err-tip-tool',
    state: (): toolState => ({
        joinObj: null,
        visible: false,
        errList: [],
        check: false,
        errmsg: null,
        index: 0,
        recommended_data: null,
        linkData: null
    }),
    getters: {
        getVisible: state => state.visible,
        getErrList: state => state.errList,
        getLength: state => state.errList.length,
        getCheck: state => state.check,
        getErrmsg: state => state.errmsg,
        getIndex: state => state.index,
        getrecommended_data: state => state.recommended_data,
        getLinkData: state => state.linkData
    },
    actions: {
        setErrList(arr: any) {
            this.errList = arr
        },
        setVisible(value: boolean) {
            this.visible = value
        },
        setCheck(value: boolean) {
            this.check = value
        },
        setErrmsg(value: any) {
            this.errmsg = value
        },
        setIndex(value: number) {
            this.index = value
        },
        setrecommended_data(value: any) {
            this.recommended_data = value
        },
        setLinkData(value: any) {
            this.linkData = value
        }
    }
})

