export default {
    saveTitle: '保存测试建模',
    updateTitle: '更新测试建模',
    searchText: "输入{'@'}搜索标签，输入名称搜索测试建模名称",
    tip1: '重复的名称或描述',
    tip2: '请填写名称和描述',
    tip3: '删除成功',
    tip5: '请输入备注',
    tip6: '确定删除该测试建模?',
    newMbt: '新增建模',
    newTem: '创建模板',
    templateMbt: '从模板建模',
    noStart: '未开始',
    noTemplate: '当前暂无模板可选',

    // MBTModeler
    reloadTip: '测试建模已重新加载',
    reloadError: '加载失败',
    start: '开始',
    end: '结束',
    concurrency: '并发',
    branch: '分支',
    previewModel: '预览',
    noPreviewData: '暂无预览数据',
    noLoad: '加载未完成，请稍后',
    noreload: '当前模型不存在AW，无需重载',
    stepPreview: '是否依照选中节点，进行单步调试',
    saveNewMbt: '保存为新模型',
    saveDirect: '直接保存',
    isSaveNew: '修改，是否保存为新模型',
    saveuser: '当前模型已被',
    // MBTModeler detail
    primary: '操作步骤',
    expected: '预期结果',
    configAW: '配置AW',
    leaveRouter: '当前编辑未保存，是否保存后退出',
    saveLeave: '保存并退出',
    exitDire: '直接退出',
    saveNoLea: '保存不退出',
    configLink: '设置连接线',
    notSave: '当前模型未保存',
    loadSuccess: '加载成功',
    editData: '数据编辑',
    editStyle: '样式修改',
    instructions: '使用说明',
    noCase: '当前模型未生成一条用例，请检查',
    editReload: '当前模型有AW被删除，请先检查或重新加载',
    deleteReload: '当前模型AW被删除，请先检查或重新加载',

    // mbt-modeler-aw-schema
    updateAw: '修改AW',
    deleteAW: '解绑AW',
    reloadAW: '刷新AW',
    clearAssert: '清空断言',
    reloadSuccess: '加载成功',
    reloadErr: '当前AW不存在',

    // mbt-preview-modal.vue
    selectTip: '请选择左边节点以展示数据',
    previewInput: '当前页面有自定义AW，是否输出为文本',

    // mbt-modeler-template-web-hook
    addHyperLinke: '添加引用',

    // mbt-modeler-template-setting
    lackTextScript: '请在模板设置的Attribute tab页选择文本模板',
    lackMetaData: '请在模板设置Meta tab页选择meta数据',
    lackDataPoolData: '请在模板设置Data Pool tab页选择数据',
    noData: '当前模板不存在，请重新配置',
    noChange: '无法感知dataPool数据是否改变，请自主选择',
}
