<script setup lang="ts">
import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined
} from '@ant-design/icons-vue'
import {
  ref,
  computed,
} from 'vue'
import { errTipTool } from "@/stores/modules/modeler-preview-err-msg";
import {MBTStore} from '@/stores/MBTModel'
import ExcelJs from 'exceljs'
import {exportFile, getExcelData} from "@/utils/fileAction";
import { identifier } from '@babel/types';
import { unhighlight } from '@/views/componentTS/mbt-modeler-preview-err-tip'
import { cloneDeep } from 'lodash';

let index = ref<number>(0)
const store = errTipTool()
const storeMbt = MBTStore()

let dataShow = ref(false)
let repeatShow = ref(false)
let notDataShow = ref(false)




let formState:any = computed(() => {
  if(store.getrecommended_data && store.getLinkData){
    let label = store.getLinkData.join()
    let data:any = JSON.parse(JSON.stringify(store.getrecommended_data))
    if(data){
      return{recommendedData: showform(label,data)}
    }else{

    }
  }
})



let datanotCovered:any = computed(() => {
  if(store.getErrmsg['data_not_covered']){
    return store.getErrmsg['data_not_covered'].map((item:any) => {
      return item.data
    })
  }else if(store.getErrmsg["more_than_1_covered"]){
    return store.getErrmsg['more_than_1_covered'].map((item:any) => {
      return item.data
    })
  }
})


//处理重复数据
let column = computed(() => {
  return storeMbt.getDataPoolColData.filter((item:any) =>item.title !== 'action')
  // let clo = cloneDeep(storeMbt.getDataPoolColData)
  // for (const key in clo) {
  //       delete clo[key].key
  //       delete clo[key].action
  //     }
  // return clo
})
let dataSource = computed(() => {
  return storeMbt.getRepeatData
})

const repeatDerive = () => {
  store.setCheck(true)
  const title = '重复数据覆盖'
  if(dataSource.value && column.value){
    exportFile(column.value , dataSource.value , title)
  }
}
const dataDerive = () => {
  const title = '重复数据覆盖'
  store.setCheck(true)
  if(datanotCovered.value && column.value){
    exportFile(column.value , datanotCovered.value , title)
  }
}

const currentErr: any = computed(() => {
  return store.getErrList[index.value]
})

let msg = computed(() => {
  let str: any
  let a: any
  
  if ( currentErr.value.err == 'textErr') {
    // return console.log(store.getErrmsg);
    str = store.getErrmsg['template error'].filter((item: any) => item.outputLang == 'yaml')
  } else if (currentErr.value.err == 'scriptErr') {
    str = store.getErrmsg['template error'].filter((item: any) => item.outputLang == 'java')
    if (str.length == 0) str = store.getErrmsg['template error'].filter((item: any) => item.outputLang == 'python')
  }else {
    str = store.getErrmsg[currentErr.value.err]
  }
  if (str[0]?.results) {
    if(str[0]?.results[0]?.error){
      a = str[0].results[0]?.error.substring(str[0].results[0]?.error.lastIndexOf('\n') + 1, str[0].results[0]?.error.length)
    }else{
      a = `(${str})`
    }
    
    return '(' + a + ')'
  }

})
// console.log(currentErr.value);

function showform(label:any,data:any){
  Object.keys(data).forEach((key:string) => {
      if(label.indexOf(key) !== -1){
        data[key] = {value:data[key],disabled:true , isSelect:false}
      }else{
        data[key] = {value:data[key],disabled:false , isSelect:true ,option:[]}
      }
  })
  if(storeMbt.getDataPoolTableData.length > 0){
      data = valueOption(storeMbt.getDataPoolTableData , data)
  }
  return data
}

function valueOption(arr: any ,data:any) {
  let newArr = Object.keys(arr[0])
  let setArr = newArr.map((item: any) => ({ name: item, type: '', values: [] }))
  arr.forEach((item: any, index: any) => {
    let keys = Object.keys(arr[0])
    if (keys) {
      setArr.forEach((tureItem: {type: string; name: string; values: any[]; }, i: any) => {
        if(tureItem.name==keys[i]){
          tureItem.values.push(item[keys[i]])
          tureItem.values=[...new Set(tureItem.values)]
        }
        if(data[tureItem.name].isSelect){
            data[tureItem.name].option = tureItem.values.map((str:any) =>{return {label:str,value:str}})
        }
      })
    }
  })

  return data
}

const dataDevise = (value:any) => {

  for(const key in formState.value.recommendedData){
    if(formState.value.recommendedData[key].value){
      formState.value.recommendedData[key] = formState.value.recommendedData[key].value
    }
  }
  formState.value.recommendedData = [formState.value.recommendedData]
  const title = '推荐数据'
  if(formState.value.recommendedData.length > 0 && column.value){
    exportFile(column.value , formState.value.recommendedData , title)
  }
}

const pre = () => {
  if (index.value === 0) return
  // unhighlight()
  index.value--
  dataShow.value = false
  repeatShow.value = false
  notDataShow.value = false
}

const next = () => {
  
  if (index.value === store.getLength - 1) return
  // unhighlight()
  index.value++
  notDataShow.value = false
  dataShow.value = false
  repeatShow.value = false
}


const close = () => {
  store.setVisible(false)
}
const checkPreview = () => {
  
  store.setCheck(true)
  store.setIndex(index.value)

  if(currentErr.value.err == 'not_reachable'){
    dataShow.value = true
    repeatShow.value = false
  }else{
    dataShow.value = false
  }
  if(currentErr.value.err == 'more_than_1_covered'){
    repeatShow.value = true
    dataShow.value = false
  }else{
    repeatShow.value = false
  }
  if(currentErr.value.err == 'data_not_covered'){
    notDataShow.value = true
    dataShow.value = false
    repeatShow.value = false
  }else{
    notDataShow.value = false
  }
}
</script>

<template>
  <!-- <a-spin class="loading-wrap" :tip="$t('common.previewLoad')" :spinning="loadErr"></a-spin> -->
  <div class="mbt-modeler-preview-err-tips">
    <div>
    <close-outlined class="close-btn" @click="close" />
    <div class="title">预览错误：{{ `${index + 1} / ${store.getLength}`}}</div>
    <div class="content">
<!--      <left-outlined class="tips-icon" @click="pre" />-->
      <div class="container">
        <span>{{ currentErr?.reason || '' }}</span>
        <span style="fontSize:.75rem">{{ msg }}</span>
      </div>
<!--      <right-outlined class="tips-icon" @click="next" />-->
    </div>
    <div class="bottom-btn">
<!--      <a-button type="link" @click="solve">定位问题</a-button>-->
      <a-button type="link" @click="pre" :disabled="index <= 0">上个错误</a-button>
      <a-button type="link" @click="next" :disabled="index + 1 >= store.getLength">下个错误</a-button>
      <a-button type="primary" @click="checkPreview">查看问题</a-button>
    </div>
    </div>
    <div v-if = 'dataShow'>
      <p>推荐数据</p>
      <a-form :model="formState"  @finish="dataDevise">
        <template v-if="formState.recommendedData">
          <a-form-item v-for="(item,index) of Object.keys(formState.recommendedData)" :label="item" :key="index">
            <a-select v-if="formState.recommendedData[item].isSelect"
            :options="formState.recommendedData[item].option"
            v-model="formState.recommendedData[item].value"
            @change="(value: any) => formState.recommendedData[item].value = value"
            ></a-select>
          <a-input v-else v-model:value="formState.recommendedData[item].value" :disabled="formState.recommendedData[item]"></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="dataDevise">{{ $t('common.export') }}</a-button>
        </a-form-item>
        </template>
      </a-form>
    </div>
    <div v-show="repeatShow">
      <a-table :columns="column" :dataSource="dataSource">
      </a-table>
      <a-button type="primary" @click="repeatDerive">{{ $t('common.export') }}</a-button>
    </div>
    <div v-show="notDataShow">
      <a-table :columns="column" :dataSource="datanotCovered">
      </a-table>
      <a-button type="primary" @click="dataDerive">{{ $t('common.export') }}</a-button>
    </div>
  </div>
</template>


<style lang="less">
.mbt-modeler-preview-err-tips {
  z-index: 9;
  position: fixed;
  margin-left: 24px;
  left: 0;
  bottom: 24px;
  // width: 384px;
  max-width: calc(100vw - 48px);
  padding: 16px 24px;
  overflow: hidden;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  .title {
    color: #333;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 16px;
  }
  .content {
    color: #333;
    line-height: 14px;
    display: flex;
    align-content: center;
    .container {
      line-height: 1.5;
    }
    .tips-icon {
      color: #3e85c7;
      cursor: pointer;
      padding: 4px;
    }
  }
  .bottom-btn {
    display: flex;
    justify-content: flex-end;
  }
  .close-btn {
    position: absolute;
    top: 16px;
    right: 24px;
    cursor: pointer;
  }
}
</style>