export default{
    noData:'模板没有数据，可双击画布空表界面，进入 Data pool 页进行编辑',
    noMeta:'模板没有设置meta数据，可双击画布空表界面，进入 Meta 页进行编辑',
    noStartEnd:'模型连接线连接节点有断点，请仔细检查',
    noTemplate:'模板没有设置模板引擎，可双击画布空表界面，进入 Attributes 页进行设置 Output Text 和 Output Script',
    textErr:'代码生成文本模板出错',
    scriptErr:'代码生成脚本模板出错',
    uncaught:'系统异常请联系管理员',
    conditionsErr:'条件表达式异常',
    noReachable:'路径无法通过',
    dataNoCover:'数据无法覆盖',
    pathCoverd:'路径上有数据重复覆盖',
    noFound:'测试模型无法找到',
    noDefined:'测试模型里面的定义是空的'
}