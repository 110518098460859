export default{
    noData:'The template has no data. You can double-click on the blank table interface on the canvas to enter the Data pool page for editing',
    noMeta:'The template does not have meta data set. You can double-click the blank table interface on the canvas to enter the meta page for editing',
    noTemplate:'The template does not have a template engine set. You can double-click the blank table interface on the canvas to enter the Attributes page to set Output Text and Output Script',
    noStartEnd:'There are breakpoints in the connection nodes of the model connection line. Please carefully check',
    textErr:'Code generation text template error',
    scriptErr:'Code generation script template error',
    uncaught:'System abnormality, please contact the administrator',
    conditionsErr:'Conditional expression exception',
    noReachable:'Path cannot pass through',
    dataNoCover:'Data cannot be overwritten',
    pathCoverd:'There is duplicate data coverage on the path',
    noFound:'Test model cannot be found',
    noDefined:'The definition in the test model is empty',
}