import { uuid } from "@/utils/Uuid";
import { cloneDeep } from "lodash";

/**
 * @param key cookie的键
 * @param value cookie的值
 * @param expires cookie的过期时间（天数），不传则关闭会话后失效，传参为负数则清除该cookie
 * @param path cookie生效路径范围，默认"/"全局生效
 */
export function setCookie(key: string, value: string | number, expires: number = 0, path: string = '/') {
  let cookie = `${key}=${encodeURIComponent(value)};path=${path};${import.meta.env.MODE === 'production' ? 'SameSite=None;Secure' : ''}`
  if (expires !== 0) {
    const date = new Date()
    date.setDate(date.getDate() + expires)
    cookie += `;expires=${date.toUTCString()}`
  }
  document.cookie = cookie
}

/**
 * @param key cookie的键
 * @returns cookie的值
 */
export function getCookie(key: string) {
  const reg = new RegExp("(^| )" + encodeURIComponent(key) + "=([^;]+)")
  const match = document.cookie.match(reg)
  return match ? decodeURIComponent(match[2]) : ""
}

/**
 * 
 * @param key 移除的cookie的键名
 */
export function removeCookie(key: string) {
  setCookie(key, '', -1)
}

/**
 * @param ms 睡眠时间（毫秒）
 * @returns Promise<unknown>
 */
export function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('wake up')
    }, ms)
  })
}

/**
 * 
 * @param arr Array<any>
 * @returns 数组随机项
 */
export function randomPick(arr: Array<any>) {
  return arr[Math.floor(Math.random() * arr.length)]
}

/**
 * 
 * @returns guid
 */
export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

/**
 * @summary 验证字段是不是手机号码
 * @param {string} str
 * @returns {Boolean}
 */
export const isPhone = (str: string) => {
  const reg = /^1[3456789]\d{9}$/
  return reg.test(str)
}

/**
 * @summary 验证字段是不是邮箱
 * @param {string} str
 * @returns {Boolean}
 */
export const isEmail = (str: string) => {
  const reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
  return reg.test(str)
}

/**
 * @zf 检查数组是否有某个属性，没有则加上，并保证该属性在数组是唯一值
 * @returns {arr}
 * @param arr
 * @param key
 * */
export function checkArrOfAttr(arr: any[], key: string) {
  const arr1 = cloneDeep(arr)
  arr1.forEach((a: any) => {
    if (!a.hasOwnProperty(key)) a[key] = uuid()
  })
  // 检查 key 属性值是否在数组唯一，否则进行更新
  const temp = arr1.map((a: any) => a[key])
  if (new Set(temp).size !== arr1.length) {
    arr1.forEach((a: any) => {
      if (temp.indexOf(a[key]) !== temp.lastIndexOf(a[key])) {
        a[key] = uuid()
      }
    })
  }
  return arr1
}

/**
 * 获取 URL 参数
 * @param url {string} 网址
 * @param name {string} 参数名
 * @return 参数名值
 * */
export function getUrlParams(url: string, name: string) {
  let queryStr:any = url.split('?')[1]
  if (!queryStr) return ''
  let keyValueList:any = queryStr.split('&')
  for (let i = 0; i < keyValueList.length; i++) {
    if (keyValueList[i].includes(name)) {
      if(keyValueList[i].split('=')[1].v){
        return keyValueList[i].split('=')[1].v
      }else{
        return keyValueList[i].split('=')[1]
      }
    }
  }
  return ''
}

/**
 * 数组排序
 * @param attr {string} 属性名
 * @param rev {string} 排序值
 * @return number
 * */
export function sortBy(attr: string, rev: string) {
  let r: any = 1
  if (rev === '') r = 1
  else r = rev === 'asc' ? 1 : -1
  return function (a: any, b: any) {
    a = a[attr]
    b = b[attr]
    if (a < b) return r * -1
    if (a > b) return r * 1
    return 0
  }
}