export default {
  settings: 'settings',
  about: 'about',
  role: 'Role',
  views: 'Views',
  action: 'Actions',
  resource: 'Resource',
  type: 'Type',
  visible: 'Visible',
  inputSearch: 'input to search role ',
  rolename: 'Role',
  view: 'View',
  resourceName: 'Resource',
  method: 'Change Mode',
  grantedResource: 'Resource',
  viewdetail: 'View',
  visibledetail: 'Visible',
  viewstore: 'View',
  userName: 'User Name',
  jobNumber: '工号',
  authrole: 'Role',
  account: 'Role',
  userauth: 'Account',
  detailRole: 'Details of the Role',
  updateRole: 'Updating the Role',
  saveRole: 'Save the Role',
  updateUserAuth: 'Updating the authrization',
  saveUserAuth: 'Save the authrization',
  detailUserAuth: 'Details of the authrization'
};
