export default {
    step1: 'First',
    step2: 'Second',
    step3: 'third',
    step4: 'Last',

    defineTem: 'Template user-manager',
    defineCell: 'Element Meaning',
    defineData: 'Data configuration',
    defineLast: 'Code generation',

    stepContent1: 'Click to select a template, set the template for code generation in Attributes, select the template for use case metadata in meta, select the template for data in Data Pool, and set resources in Resources',
    stepContent2: 'The meaning of each element. When the mouse moves in, it will prompt that group meets the cycle, retry and other scenarios, ifGroup meets the configuration conditions, and section configures the pre or post conditions',
    stepContent3: 'Pull elements to the canvas and edit data on the right',
    stepContent4: 'After modeling, click Save to preview the generated code. If the AW in the current modeling does not exist, you need to reload it',
}