import {getUrlParams} from "@/utils/index";
import {isEmpty} from "lodash";
import MultipleSelectItem from '@/components/basic/itea-schema-item/multiple-select-item.vue'
import { message } from 'ant-design-vue'

let messaged: boolean = false

/**
 * @author zf
 * @param {any} meta
 * @return {Object}
 * 测试桌面返回的数据需要转化为 schema 数据结构和 key-value 值
 * 返回的数据有以下四种：
 * key: value
 * key: {
 *     label: xx
 *     value: xx
 * }
 * key: [
 *     {
 *         label: xx
 *         key: xx
 *     }
 *     ...
 * ]
 * key = [ value1, value2]
 * */
export function desktopMeta2KV(meta: any) {
    const schema: any = {
        type: 'object',
        properties: {}
    }
    const data: any = {}
    const uiSchema: any = {}
    if (isEmpty(meta) || !meta) return {
        schema,
        data
    }
    Object.keys(meta).forEach((a: any) => {
        const type = Object.prototype.toString.call(meta[a])
        const value = meta[a]
        switch (type) {
            case '[object String]':
            case '[object Number]':
            {
                schema.properties[a] = {
                    type: typeof value,
                    title: a,
                    readOnly: true
                }
                data[a] = value
                break
            }
            case '[object Object]': {
                schema.properties[value.label] = {
                    type: typeof value.value,
                    title: value.label,
                    readOnly: true
                }
                data[value.label] = value.value
                break
            }
            case '[object Array]': {
                let val = value.map((b: any) => {
                    const v = b.value ? b.value : b
                    return {
                        label: v,
                        value: v
                    }
                })
                if (value.length === 0) {
                    schema.properties[a] = {
                        type: "string",
                        title: a,
                        readOnly: true
                    }
                } else if (Object.prototype.toString.call(value[0]) === '[object Object]') {
                    schema.properties[a] = {
                        type: "string",
                        title: a,
                        readOnly: true,
                        patternProperties: false,
                    }
                    uiSchema[a] = {
                        'ui:widget': MultipleSelectItem,
                        'ui:options': val,
                        'ui:readOnly': true
                    }
                    data[a] = value.map((b: any) => b.value)
                } else {
                    schema.properties[a] = {
                        type: "string",
                        title: a,
                        patternProperties: false,
                        readOnly: true,
                    }
                    uiSchema[a] = {
                        'ui:widget': MultipleSelectItem,
                        'ui:options': val,
                        'ui:readOnly': true
                    }
                    data[a] = value
                }
            }
        }
    })
    return {
        schema,
        data,
        uiSchema
    }
}

/**
 * 判断是否是内嵌模式
 * */
export function isEmbedded() {
    if (window.top !== window.self) {
    }
    return window.top !== window.self
}

/**
 * 判断是否是内嵌模式下的只读模式
 * 需要 url 有 authority 字段且值为 viewer
 * */
export function isEmbeddedReadOnly() {
    if (!isEmbedded()) return false
    const flag = getUrlParams(location.href, 'authority') !== 'editor'
    if (flag && !messaged) {
        messaged = true
        message.info('您目前只有只读权限')
    }
    return flag
}

/**
 * 获取内嵌 token
 * 需要 url 带有 token 字段
 * */
export function getDesktopToken() {
    return getUrlParams(location.href, 'token') || ''
}