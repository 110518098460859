export default {
    inputSearch: 'input to search',
    inputSearch1: "input {'@'} to search tags, input name to search",
    addParams: 'Add Params',
    addSNode: 'Add Sibling Node',
    addCNode: 'Add Child Node',
    modifyNode: 'Modify Node',
    delNode: 'Delete node',
    moveSelected: 'Move selected',
    delTip: 'Are you sure delete this AW?',
    tip1: 'This node already contains a node of',
    tip2: 'Please complete the modification first',
    emptyTemp: 'Template or template_en is required',
    pageTotal: '{total} in total',
    selectAwTip: 'Please select the Aw to be added',
    paramsSave:'Parameter must be set to type'
}